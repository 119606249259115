import { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom'
import {getPrincipalMessage} from'../Service/Api'
const HomeMessage = () => { 
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1, category: "Principal", message: "All our lives we have been taught that education fetches tremendous change in society. It does. Education is the most significant instrument in determining the economic and societal transformation of people. The best thing about the education system in India is that it prepares the foundation of a child’s schooling very skilfully by giving equal importance to all subjects. Educational institutions contribute to the shaping up of the efficiency of manpower within a society. As of late, the invasion of technology in every sphere of life has changed the outlook of education in India." , name: "Fr. Shaji Alappurath", images: "/Images/principal.jpg" }
   ];
  return (
     <>
     {data && data.length > 0 ? (data.map((item, index) => ( 
         <div className="messagebox" key={index}>
            <div className="messageboximg">
               <div className="messageboxname"> <h6>{item.name} <span>{item.category}</span></h6> </div>
                 <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} className="img-fluid" alt="St. Francis School, Harmu, Ranchi"/>
            </div>
            <div className="messageboxcontent">
                 <h5>Principal’s Message</h5>
                  <div className="messageboxdesc">
                        {item.message} 
                  </div>
                   <Link to="/PrincipalMessage"> <img src="/Images/readmore.png" className="img-fluid" alt="St. Francis School, Harmu, Ranchi"/></Link>
            </div>
        </div>
        ))) : (emptyData.map((item, index) => (
            <div className="messagebox" key={index}>
            <div className="messageboximg">
               <div className="messageboxname"> <h6>{item.name} <span>{item.category}</span></h6> </div>
                 <img src={item.images} className="img-fluid" alt="St. Francis School, Harmu, Ranchi"/>
            </div>
            <div className="messageboxcontent">
                 <h5>Principal’s Message</h5>
                  <div className="messageboxdesc">
                         {item.message} 
                  </div>
                   <Link to="/PrincipalMessage"> <button className="viewall">Read More</button></Link>
            </div>
        </div>
        )))}


        <div className="messagebox"> 
            <div className="msgdcs messageboxcontent">
                <h5>Vice Principal’s Message</h5>
                 <p>Message on Modern Education System</p>
                <div className="messageboxdesc">
                Our present education system seems to be insufficient. For, it is based on the theoretical knowledge. The students of today play no active role in attainment of knowledge. Things are loaded on one’s mind which he/she cannot digest, he only crams and therefore they never become his own. That is why, Dr, Annie Basant has justly said, ‘our education system is like filling students head with lot of disjointed facts poured into the heads as into a basket; to be emptied out again in examination hall and empty basket carried out again into the world.’
                </div>
                <Link to="/VicePrincipalMessage">  <button className="viewall">Read More</button></Link>
            </div>
            <div className="messageboximg">
               <div className="messageboxname"> <h6>Fr. Sumit Tirkey, TOR <span> VICE PRINCIPAL</span></h6> </div>
                <img src="/Images/viceprincipal.jpg" className="img-fluid" alt="St. Francis School, Harmu, Ranchi"/>
            </div>
        </div>
        <div className="messagebox">
        <div className="messageboximg">
            <div className="messageboxname"> <h6>Fr. Jasman Toppo <span> VICE PRINCIPAL</span></h6> </div>
             <img src="/Images/viceprincipal1.jpg" className="img-fluid" alt="St. Francis School, Harmu, Ranchi"/>
        </div>
        <div className="messageboxcontent">
             <h5>Vice Principal’s Message</h5>
               <p>Dear Students and Parents</p>
              <div className="messageboxdesc">
              Founded in 1978, St. Francis School, Harmu, Ranchi is one of the first schools to believe in the importance of educating boys and girls to fulfil their potential, giving them the skills and confidence to take their place with pride in a modernising and fast-moving world. That tradition remains at our core.
              From its very beginning, and in advance of its time, St. Francis School, Harmu embraced a liberal ideology.
              </div>
               <Link to="/VicePrincipalMessage">  <button className="viewall">Read More</button></Link>
        </div>
    </div>
     </>
  )
}

export default HomeMessage
