import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { getEvents } from '../Service/Api';
import { Link } from 'react-router-dom'; 
import CustomNextArrow from '../Pages/CustomNextArrow';
import CustomPrevArrow from '../Pages/CustomPrevArrow';

const Events = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventData = await getEvents();
        setData(eventData);  
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const empty = [
    { title: "Event Title", description: "Stay Tuned For More Updates", date: "DD,MM YY", time: "00:00", venue: "School Campus" },
    { title: "Event Title", description: "Stay Tuned For More Updates", date: "DD,MM YY", time: "00:00", venue: "School Campus" },
    { title: "Event Title", description: "Stay Tuned For More Updates", date: "DD,MM YY", time: "00:00", venue: "School Campus" }
  ];

  const settings = { 
    autoplay: true,
    infinite: false,
    autoplaySpeed: 2500,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 1 } },
      { breakpoint: 992, settings: { slidesToShow: 2 } },
      { breakpoint: 767, settings: { slidesToShow: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } }
    ]
  };

  return (
    <>
      <Slider {...settings}>
      {data && data.length > 0 ?  (data.map((item,index) => {
        const date = new Date(item.date);
        const mon = monthNames[date.getMonth()];
        const eventdate = date.getDate();
        const year = date.getFullYear();

        return (
 
       <div className="item" key={index}>
            <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank" className="event-blk">
              <div className="event-image"> 
                <div className="date">
                  <h3>{eventdate}, {mon} {year}</h3>
                </div>
                <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.images}`} alt="Event" />
              </div>
              <div className="content">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
              <div className="homeeventbottom">
                <p>{item.venue}</p>
                <p>{item.time}</p>
              </div>
            </Link>
          </div>
   );
      })
    ) : (empty.map((item,index) => (
   <div className="item" key={index}>
          <Link to="#" target="_blank" className="event-blk">
            <div className="event-image"> 
              <div className="date">
                <h3>{item.date}</h3>
              </div>
              <img src="/Images/events.jpg" alt="Event" />
            </div>
            <div className="content">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
            <div className="homeeventbottom">
              <p>{item.venue}</p>
              <p>{item.time}</p>
            </div>
          </Link>
        </div>
 )))}
      </Slider>
    </>
  );
};

export default Events;
