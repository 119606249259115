import React from 'react'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Highlight from'../Components/Highlight'     
const Header = () => {
  return (
     <>
     <Highlight/>
     <header>
       <div className="container-fluid">
         <div className="row">
             <div className="col-md-12 col-lg-3 col-xxl-3 col-xs-12">
                 <Link to="/"><img src="/Images/logo.png" alt="St. Francis School, Ranchi" className="img-fluid logo" /></Link>
             </div>
             <div className="col-md-12 col-lg-9 col-xxl-9 col-xs-12 d-flex space-between"> 
                 <div className="social-media">
                     <ul>
                         <li><Link to="#" target="_blank"><img src="/Images/twitter.png" alt="St. Francis School, Ranchi" /></Link></li>
                         <li><Link to="#" target="_blank"><img src="/Images/insta.png" alt="St. Francis School, Ranchi" /></Link></li>
                         <li><Link to="#" target="_blank"><img src="/Images/facebook.png" alt="St. Francis School, Ranchi" /></Link></li>
                       </ul>
                 </div>
                 <div className="header-contact">
                     <div className="headercon">
                         <Link to="mailto:sfs_harmu@hotmail.com"> <div><img src="/Images/email.gif" alt="St. Francis School, Ranchi" /></div>
                               <div> <h6>EMAIL US</h6>
                                    <p>sfs_harmu@hotmail.com</p>
                               </div>
                              </Link>
                         
                     </div>
                     <div className="headercon">
                     <Link to="tel:8987705291"> <div><img src="/Images/call.gif" alt="St. Francis School, Ranchi" /></div>
                           <div> <h6>CALL US</h6>
                                <p>8987705291 <br/> 9472708434</p>
                           </div>
                          </Link>
                     
                 </div>
                 <div className="headercon">
                         <Link to="https://maps.app.goo.gl/Yt66nsFReFoE5Bop8"> <div><img src="/Images/location.gif" alt="St. Francis School, Ranchi" /></div>
                               <div> <h6>OUR LOCATION</h6>
                                    <p>  St. Francis School  Harmu, Ranchi - 834002, Jharkhand    </p>
                               </div>
                              </Link> 
                     </div>
                </div>
                 <div className="pp-login">
                     <Link to="https://sfsr.campussoft.net/" target="_blank"><img src="/Images/Campulogo.png" alt="St. Francis School, Ranchi"/></Link>
                 </div>
             </div>
            <div className="menu-sec"> 
               <nav className="navbar navbar-expand-lg navbar-dark">
                 <div className="container-fluid">
                   <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation"> 
                 <span className="navbar-toggler-icon"></span> 
                 </button>
                  <div className="collapse navbar-collapse nav-fill justify-content-center" id="main_nav">
                     <ul className="navbar-nav">
                         <li>
                             <NavLink className="nav-link" id="school" to="/">  <img src="/Images/home.png" alt="St. Francis School, Ranchi"/></NavLink>
                         </li>
                         <li className="nav-item dropdown"> 
                              <div className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown">  About Us </div>
                                    <ul className="dropdown-menu">
                                        <li><NavLink className="dropdown-item" to="/OurSchool">Our school</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Objective">Objective</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Curriculum">Curriculum</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Discipline">Discipline</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Vision">Vision</NavLink></li> 
                                        <li><NavLink className="dropdown-item" to="/PrincipalMessage">Principal's Message</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/VicePrincipalMessage">Vice Principal's Message</NavLink></li>
                                    </ul> 
                         </li>
                         <li className="nav-item dropdown"> 
                           <div className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown"> Rules </div>
                               <ul className="dropdown-menu">
                                    <li><NavLink className="dropdown-item" to="/AdmissionProcedure">Admission Procedure </NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/SchoolFee">School Fees </NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/GeneralRules">General Rules </NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/LibraryRules">Library Rules </NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/Attendance">Attendance </NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/PromotionPolicy">Promotion Policy </NavLink></li>
                               </ul> 
                         </li>
                         <li className="nav-item dropdown"> 
                          <div className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown"> Academic </div>
                             <ul className="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="/Faculty">Faculty</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/Tc">Transfer Certificate</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/AcademicCalendar">Academic Calendar </NavLink></li>
                                <li><NavLink className="dropdown-item" to="/Uniform">Uniform</NavLink></li>
                             </ul> 
                       </li>
                       <li className="nav-item dropdown"> 
                       <div className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown"> Facilities </div>
                           <ul className="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="/OurFacilities">Our Facilities </NavLink></li>
                                <li><NavLink className="dropdown-item" to="/SmartClass">Smart Class </NavLink></li>
                                <li><NavLink className="dropdown-item" to="/EnglishLanguageLab">English Language Laboratory  </NavLink></li>
                                <li><NavLink className="dropdown-item" to="/CoCirricular">Co-curricular Activities</NavLink></li>
                           </ul> 
                     </li>
                     <li><NavLink className="nav-link" to="/gallery">Gallery</NavLink></li>
                     <li><NavLink className="nav-link" to="/career">Career</NavLink></li>
                     <li><NavLink className="nav-link" to="/contact">Contact Us</NavLink></li>
                     </ul>
                 </div>  
             </div>
             </nav>
           </div>
         </div>
      </div>
    </header>
     </>
  )
}

export default Header
